import React from 'react';

import Grid from '@material-ui/core/Grid';
import { BlogPostItem } from '..';

const BlogPostCollection = ({ posts }) => {
  return (
    <Grid container spacing={4}>
      {posts.map((post, idx) => (
        <Grid item key={post.id} xs={12} sm={idx === 0 ? 12 : 4}>
          <BlogPostItem post={post} isHero={idx === 0} />
        </Grid>
      ))}
    </Grid>
  );
};

export default BlogPostCollection;
