import React from 'react';

import { useNavigate } from '@reach/router';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import CONSTANTS from '../../util/constants';

const useStyles = makeStyles({
  media: ({ isHero }) => ({
    height: isHero ? 400 : 160,
  }),
  root: {
    marginBottom: '1.5rem',
  },
});

const BlogPostItem = ({ post, isHero }) => {
  const classes = useStyles({ isHero });
  const navigate = useNavigate();

  const { date, heroImage, title, subtitle } = post.frontmatter;

  const heroImageToUse = heroImage || CONSTANTS.DEFAULT_HERO_IMAGE;

  const cardClicked = () => {
    const postUrlSplit = post.fileAbsolutePath.split('/');
    const postUrlSlug = postUrlSplit[postUrlSplit.length - 1].split('.')[0];
    navigate(`/blog/${postUrlSlug}/`);
  };

  return (
    <Card onClick={cardClicked} className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={heroImageToUse.url}
          title={title}
        />
        <CardContent>
          <Typography
            variant="overline"
            className={classes.title}
            color="secondary"
          >
            {date}
          </Typography>
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
          <Typography gutterBottom variant="subtitle2">
            {subtitle}
          </Typography>
          <div>
            <Typography variant="body1" color="textSecondary" component="p">
              {post.excerpt}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BlogPostItem;
