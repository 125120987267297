import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Typography from '@material-ui/core/Typography';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = ({ alt, figcaption, src }) => {
  const { images } = useStaticQuery(graphql`
    query {
      images: allImageSharp {
        edges {
          node {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              originalName
            }
          }
        }
      }
    }
  `);
  const image = images.edges.find(edge => edge.node.fluid.originalName === src);
  if (!image) return null;

  return (
    <figure>
      <Img fluid={image.node.fluid} alt={alt} />
      <Typography variant="caption" component="figcaption">
        {figcaption || alt}
      </Typography>
    </figure>
  );
};

export default Image;
