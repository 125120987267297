// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---data-blog-00-hello-world-mdx": () => import("./../data/blog/00-hello-world.mdx" /* webpackChunkName: "component---data-blog-00-hello-world-mdx" */),
  "component---data-blog-01-introducing-pistola-mdx": () => import("./../data/blog/01-introducing-pistola.mdx" /* webpackChunkName: "component---data-blog-01-introducing-pistola-mdx" */),
  "component---data-blog-02-user-survey-results-mdx": () => import("./../data/blog/02-user-survey-results.mdx" /* webpackChunkName: "component---data-blog-02-user-survey-results-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-podcast-js": () => import("./../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */)
}

