import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f50057', // or whatever colors you need
    },
    secondary: {
      main: '#ff5722',
    },
  },
  typography: {
    h1: {
      fontSize: '4rem',
    },
    h2: {
      fontSize: '2rem',
    },
  },
});

theme = responsiveFontSizes(theme);
export default theme;
