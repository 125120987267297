export default {
  DEFAULT_HERO_IMAGE: {
    url:
      'https://images.unsplash.com/photo-1458708606976-4af51a03c931?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjF9&auto=format&fit=crop&w=3887&q=80',
    author: {
      name: 'Adrian Williams',
      url: '',
    },
  },
};
