module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"blog":"/opt/build/repo/src/templates/Post.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"pistola","short_name":"pistola","start_url":"/","background_color":"#ffffff","theme_color":"#f50057","display":"Pistola","icon":"src/images/pistola-1024.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ab142ef70f8a86bc2b8d6a1c0023213a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fathom/gatsby-browser.js'),
      options: {"plugins":[],"trackingUrl":"your-fathom-instance.com","siteId":"EGVMXVDA","whitelistHostnames":["pistola.io","www.pistola.io"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"53RCpxgD84y1e3lDgsklrw0G6HpuFZSa","devKey":"53RCpxgD84y1e3lDgsklrw0G6HpuFZSa","trackPage":true,"delayLoad":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
