import React, { useEffect, useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import { isValidEmail } from '../../util/email';

const MAILCHIMP_RESPONSE_TYPES = {
  success: 'success',
  error: 'error',
};

const useStyles = makeStyles(theme => ({
  button: {
    maxHeight: '2.5rem',
    alignSelf: 'flex-end',
    marginLeft: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    color: theme.palette.text.secondary,
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
}));

const NewsletterForm = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [mailchimpResponse, setMailchimpResponse] = useState();

  useEffect(() => {
    if (email && isValidEmail(email)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [email, disabled, setDisabled]);

  const handleChange = event => {
    setEmail(event.target.value);
  };

  const onSubmit = async event => {
    event.preventDefault();
    const response = await addToMailchimp(email);
    setMailchimpResponse(response);
  };

  let formContents;

  if (
    mailchimpResponse &&
    mailchimpResponse.result === MAILCHIMP_RESPONSE_TYPES.success
  ) {
    // display success stuff only if we did the thing right
    // otherwise we're either in an unsubmitted or error state
    formContents = (
      <Typography
        variant="subtitle1"
        component="h1"
        color="secondary"
        align="left"
      >
        {mailchimpResponse.msg}
      </Typography>
    );
  } else {
    formContents = (
      <form className={classes.form} onSubmit={onSubmit} id="newsletter-signup">
        <TextField
          required
          id="outlined-basic"
          label="email"
          placeholder="someone@email.com"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={email}
          onChange={handleChange}
          error={
            mailchimpResponse &&
            mailchimpResponse.result === MAILCHIMP_RESPONSE_TYPES.error
          }
          helperText={
            mailchimpResponse && mailchimpResponse.result ? (
              <span
                dangerouslySetInnerHTML={{ __html: mailchimpResponse.msg }}
              />
            ) : null
          }
        />
        <Button
          disabled={disabled}
          type="submit"
          variant="contained"
          color="secondary"
          className={classes.button}
        >
          Submit
        </Button>
      </form>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" component="h1" color="secondary" align="left">
        Early access
      </Typography>
      <Typography variant="body2" align="left" className={classes.subtitle}>
        {`Want exclusive early access to Pistola? Join the beta list here.`}
      </Typography>
      {formContents}
    </Paper>
  );
};

export default NewsletterForm;
