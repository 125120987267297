import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { Logo, Link } from '.';

const useStyles = makeStyles(theme => ({
  logo: {
    marginTop: '1rem',
    marginBottom: '1rem',
    maxHeight: '2rem',
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'flex-end',
    overflowX: 'auto',
    minHeight: '0px',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    textTransform: 'uppercase',
  },
}));

const sections = [
  {
    title: 'Podcast',
    url: '/podcast',
  },
  {
    title: 'Blog',
    url: '/blog',
  },
];

export default function Header(props) {
  const classes = useStyles();
  const { title } = props;

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <Link to="/">
          <Logo className={classes.logo} />
        </Link>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          className={classes.toolbarTitle}
        >
          {title}
        </Typography>
        {sections.map(section => (
          <Link
            color="inherit"
            noWrap
            key={section.title}
            variant="body2"
            to={section.url}
            className={classes.toolbarLink}
          >
            {section.title}
          </Link>
        ))}
        {/* <Button variant="outlined" size="small">
          Sign up
        </Button> */}
      </Toolbar>
      {/* <Toolbar
        component="nav"
        variant="dense"
        className={classes.toolbarSecondary}
      ></Toolbar> */}
    </>
  );
}

Header.propTypes = {
  title: PropTypes.string,
};
